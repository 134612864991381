import React, { useEffect, useState } from "react";
import { FaAngleLeft, FaCartShopping, FaPlus } from "react-icons/fa6";

import { Helmet } from "react-helmet";
import { CDN_BASE_URL } from "config";
import { priceComma } from "utils/priceComma";
import { httpReauest } from "utils/httpRequest";
import Header from "components/header";
import Footer from "components/Footer";
import Navbar from "components/dashboard/Navbar";
import ImageUpload from "components/dashboard/ImageUpload";
import Loading from "components/common/Loading";
import UserAddress from "components/dashboard/UserAddress";

const PartnerAccountPage = () => {
  const [data, setData] = useState();
  const [orders, setorders] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user?.token) {
      window.location.href = window.origin + "/sign";
    }
    getData();
  }, []);

  async function getData() {
    setloading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const newData = await httpReauest(
      "GET",
      "/partner/info/" + user?._id,
      {},
      { "x-access-token": user?.token }
    ).catch(() => {
      localStorage.setItem("user", null);
      window.location.href = window.origin + "/sign";
    });

    setData(newData.data.data.user);
    setorders(newData.data.data.orders);
    setloading(false);
  }

  return (
    <>
      {loading && (
        <div className="fixed left-0 top-0 w-full h-full bg-black/40 flex justify-center items-center">
          <Loading />
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"پنل کاربری"}</title>
      </Helmet>
      <Header />
      <div
        dir="rtl"
        className="py-6 lg:py-24  flex justify-center bg-gray-100  mt-[108px] lg:mt-[83px] text-right"
      >
        <div className=" w-full container px-[8px] lg:px-10">
          <div className="lg:grid-cols-4 grid gap-3">
            <Navbar user={data} />

            <span className="   lg:col-span-3 ">
              <>
                <div className="rounded-[12px] p-3 bg-white ">
                  <h2 className="font-bold text-xl">اطلاعات من</h2>
                  <div className="grid lg:grid-cols-3 gap-3 my-3">
                    <div className=" ">
                      <span className="font-bold">نام و نام خانوادگی</span>
                      <p className="text-gray-500">{data?.name}</p>
                    </div>
                    <div className=" ">
                      <span className="font-bold">شماره موبایل</span>
                      <p>{data?.phone}</p>
                    </div>
                    <div className=" ">
                      <span className="font-bold">کد ملی</span>
                      <p>{data?.nationalCode}</p>
                    </div>
                  </div>
                </div>
                <UserAddress setData={setData} data={data} />

                <div className="mb-6 rounded-[12px] p-3 bg-white ">
                  <h2 className="font-bold text-lg mb-3">مدارک</h2>
                  <div className="grid lg:grid-cols-2 gap-3 ">
                    <ImageUpload
                      label="کارت ملی"
                      id="nationalImage"
                      url={"/user/license/national/" + data?._id}
                      preview={
                        data?.nationa_card
                          ? CDN_BASE_URL + data?.nationa_card
                          : null
                      }
                    />
                    <ImageUpload
                      label="جواز کسب"
                      id="workLicense"
                      url={"/user/license/workLicense/" + data?._id}
                      preview={
                        data?.workLicense
                          ? CDN_BASE_URL + data?.workLicense
                          : null
                      }
                    />
                    <div className="mt-1 rounded-md bg-blue-200 text-blue-600 p-2 col-span-full">
                      تصویر شما باید واضح و خوانا باشد، بهتر است از تصویر اسکن
                      شده مدارک استفاده کنید
                    </div>
                  </div>
                </div>
              </>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PartnerAccountPage;
