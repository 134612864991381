import { BASE_URL } from "config";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";

const ImageUpload = ({ id, url, label, preview }) => {
  const [img, setImg] = useState();
  const [previewImg, setPreviewImg] = useState(preview ? preview : null);

  console.log(previewImg)

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function handleUpload() {
    if (!img) {
      return toast.error("تصویری بارگزاری نشده");
    }
    const user = JSON.parse(localStorage.getItem("user"));

    const formdata = new FormData();
    formdata.append(id, img, img.name);
    await fetch(BASE_URL + url, {
      method: "POST",
      body: formdata,
      headers: { "x-access-token": user?.token },
    }).then((res) => res.status === 201 && toast.success("موفقیت آمیز"));
  }

  return (
    <span>
      <div className="flex justify-between items-center mb-2">
        <p className=" text-[18px] font-bold">{label}</p>
        <button
          onClick={handleUpload}
          className="bg-red-500 text-white px-3 py-1 rounded-[8px] mt-2 hover:bg-red-700 "
        >
          آپلود
        </button>
      </div>

      <label
        htmlFor={id}
        className="border-[2px] border-dashed rounded-[12px] h-[170px] w-full flex justify-center items-center cursor-pointer p-2"
      >
        {previewImg ? (
          <img
            src={previewImg}
            className="w-full h-full object-contain"
            alt=""
          />
        ) : (
          <FaPlus size={44} />
        )}
      </label>

      <input
        onChange={(e) => {
          handleImg(e);
        }}
        type="file"
        className="hidden"
        id={id}
        accept="image/png, image/jpg, image/jpeg"
      />
    </span>
  );
};

export default ImageUpload;
