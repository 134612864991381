import { useFormik } from "formik";
import { Edit } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { httpReauest } from "utils/httpRequest";
import * as Yup from "yup";

const UserAddress = ({ data, setData }) => {
  const [editing, setEditing] = useState(false);
  const [states, setStates] = useState();
  const [cities, setCities] = useState();

  const formik = useFormik({
    initialValues: { state: "", city: "", address: "", postalCode: "" },
    validationSchema: Yup.object({
      state: Yup.string().required("الزامی است"),
      city: Yup.string().required("الزامی است"),
      address: Yup.string().required("الزامی است"),
      postalCode: Yup.string().required("الزامی است"),
    }),
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));

      await httpReauest("POST", "/user/address/" + data?._id, values, {
        "x-access-token": user?.token,
      }).then((res) => {
        console.log(res?.data?.data)
        setEditing(false);
        setData(res?.data?.data);
        toast.success("موفقیت آمیز");
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (!formik.values.city) {
      getCitis();
    }
  }, [formik.values.state]);

  async function getData(params) {
    await fetch("https://www.iran-locations-api.ir/api/v1/fa/states").then(
      async (res) => {
        const data = await res.json();
        setStates(data);
      }
    );
  }

  async function getCitis() {
    await fetch(
      "https://www.iran-locations-api.ir/api/v1/fa/cities?state=" +
        formik?.values?.state
    ).then(async (res) => {
      const data = await res.json();
      if (formik?.values?.state) {
        setCities(data[0].cities);
      } else {
        setCities(data);
      }
    });
  }

  return (
    <div className="my-6 rounded-[12px] p-3 bg-white ">
      <h2 className="font-bold text-lg mb-3 flex gap-2 items-center">
        آدرس{" "}
        <button
          onClick={() => setEditing(!editing)}
          className="flex gap-1 items-center font-[500] bg-slate-600 text-white px-2 py-1 text-[14px] rounded"
        >
          {editing ? "لغو" : "افزودن/ادیت"}
          <Edit />
        </button>
      </h2>
      {editing ? (
        <form
          onSubmit={formik.handleSubmit}
          className="grid lg:grid-cols-3 gap-3 my-3"
        >
          <div className=" ">
            <label
              htmlFor="state"
              className="block text-sm font-medium text-gray-700"
            >
              استان
            </label>
            <select
              id="state"
              name="state"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
            >
              <option></option>
              {states?.map((e) => (
                <option>{e.name}</option>
              ))}
            </select>
            {formik.touched.state && formik.errors.state ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.state}
              </div>
            ) : null}
          </div>
          <div className=" ">
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              شهر
            </label>
            <select
              id="city"
              name="city"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
            >
              <option></option>
              {cities?.map((e) => (
                <option>{e.name}</option>
              ))}
            </select>
            {formik.touched.city && formik.errors.city ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.city}
              </div>
            ) : null}
          </div>
          <div className=" ">
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              کدپستی
            </label>
            <input
              id="postalCode"
              name="postalCode"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postalCode}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
            />
            {formik.touched.postalCode && formik.errors.postalCode ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.postalCode}
              </div>
            ) : null}
          </div>
          <div className=" ">
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              آدرس
            </label>
            <textarea
              id="address"
              name="address"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.address}
              </div>
            ) : null}
          </div>
          <div className="col-span-full">
            <button className="bg-red-500 text-white px-3 py-1 rounded-[8px]  hover:bg-red-700 ">
              تایید
            </button>
          </div>
        </form>
      ) : (
        <div className="grid lg:grid-cols-3 gap-3 my-3">
          <div className=" ">
            <span className="font-bold">استان</span>
            <p>{data?.state}</p>
          </div>
          <div className=" ">
            <span className="font-bold">شهر</span>
            <p>{data?.city}</p>
          </div>
          <div className=" ">
            <span className="font-bold">کدپستی</span>
            <p>{data?.postalCode}</p>
          </div>
          <div className=" ">
            <span className="font-bold">آدرس</span>
            <p className="text-gray-500">{data?.address}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAddress;
