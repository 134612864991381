import Loading from "components/common/Loading";
import { CDN_BASE_URL } from "config";
import React, { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "utils/httpRequest";

const SuccessfullPayment = () => {
  const getUrl = "https://api.cryptocloud.plus/v2/invoice/merchant/info";

  const headers = new Headers({
    Authorization:
      "Token eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiTWpNek9UTT0iLCJ0eXBlIjoicHJvamVjdCIsInYiOiJjMjk0OTYzZmIxNGJjOTIzMjEyZWViN2YxM2VlMDY2Y2IwNDZjMTVmYThhYzllNDc4ZTgyMzc1ZGI5ZjI3NGFmIiwiZXhwIjo4ODEyMDY4MzM5Mn0.oUrG2q4Ta7eIPaNCb-nCOVaXxE1BjAcsC9x-u-A7uP0",
    "Content-Type": "application/json",
  });

  function getStatus() {
    const newbody = { uuids: [localStorage.getItem("uuid")] };
    fetch(getUrl, { method: "POST", headers, body: JSON.stringify(newbody) })
      .then(async (response) => {
        if (response.ok) {
          const res = await response.json();
          const result = res.result[0];
          const body = {
            amount: result?.amount_in_fiat,
            currency: result?.currency?.fullcode,
            uuid: result?.uuid,
            created: result?.created,
          };
          const user = JSON.parse(localStorage.getItem("user"));
          await httpReauest("POST", "/transaction/create", body, {
            "x-access-token": user?.token,
          })
            .then((res) => {
              window.location.href = window.origin + "/dashboard/wallet";
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
            });
        } else {
          return Promise.reject("Auth error");
        }
      })
      .catch((error) => {
        console.error("Fail:", error);
      });
  }

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <div className="flex flex-col gap-2 justify-center items-center w-screen h-screen ">
      <FaCheck className="text-green-500" size={50} />
      <h2 className="font-bold text-[32px]">Successfull Payment</h2>
      <Loading />
    </div>
  );
};

export default SuccessfullPayment;
