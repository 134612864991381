import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "components/header";
import Footer from "components/Footer";
import { httpReauest } from "utils/httpRequest";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const Signpage = () => {
  const [step, setStep] = useState(1);

  const naviagte = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      naviagte("/dashboard");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      phone: "",
      code: "",
      name: "",
      nationalId: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required("Phone number is required")
        .matches(/^[0-9]{11}$/, "Phone number must be 11 digits"),
      code: Yup.string()
        .required("Code is required")
        .matches(/^[0-9]{6}$/, "Code must be 6 digits"),
      name: Yup.string().required("Name is required"),
      nationalId: Yup.string()
        .required("National ID is required")
        .matches(/^[0-9]{10}$/, "National ID must be 10 digits"),
    }),
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));

      await httpReauest(
        "POST",
        "/user/info/" + user?._id,
        {
          nationalCode: values.nationalId,
          name: values.name,
        },
        { "x-access-token": user?.token }
      )
        .then(() => naviagte("/dashboard"))
        .catch(() => toast.error("Somthing is wrong"));
    },
  });

  const nextStep = async () => {
    if (step === 1) {
      await httpReauest(
        "POST",
        "/user/login",
        { phone: formik.values.phone },
        {}
      ).then(() => setStep(step + 1));
    }
    if (step === 2) {
      await httpReauest(
        "POST",
        "/user/login/accept",
        { phone: formik.values.phone, code: formik.values.code },
        {}
      )
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res?.data?.data));
          if (res?.data?.data?.name && res?.data?.data?.nationalCode) {
            naviagte("/dashboard");
          } else {
            setStep(step + 1);
          }
        })
        .catch(() => toast.error("Code is wrong"));
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <>
      <div className="min-h-[80vh] flex items-center justify-center bg-gray-100   mt-[108px] lg:mt-[83px]">
        <Header />
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white px-8 pb-3 rounded-lg shadow-md w-96"
          dir="rtl"
        >
          <img
            src="/logo.png"
            className="w-full max-w-[270px] mx-auto"
            alt="Emporium point logo"
          />
          <h2 className="text-2xl font-bold mb-6 ">ورود / ثبت نام</h2>

          {step === 1 && (
            <div>
              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  شماره موبایل
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>

              <button
                type="button"
                onClick={nextStep}
                className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                ورود
              </button>
              <Link
                to={"/sign/partner"}
                onClick={prevStep}
                className="w-full text-center block bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mt-2"
              >
                ورود به عنوان همکار
              </Link>
            </div>
          )}

          {step === 2 && (
            <div>
              <p className="mb-3 font-bold">
                برای تکمیل مراحل ثبت نام، کد ارسال شده را وارد کنید!
              </p>
              <div className="mb-4">
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-700"
                >
                  کد ارسالی
                </label>
                <input
                  id="code"
                  name="code"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
                />
                {formik.touched.code && formik.errors.code ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.code}
                  </div>
                ) : null}
              </div>

              <button
                type="button"
                onClick={prevStep}
                className="w-full bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mb-2"
              >
                ویرایش شماره
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                تایید کد
              </button>
            </div>
          )}

          {step === 3 && (
            <div>
              <p className="mb-3 font-bold">اطلاعات خود را کامل کنید</p>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  نام و نام خانوادگی
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="nationalId"
                  className="block text-sm font-medium text-gray-700"
                >
                  کد ملی
                </label>
                <input
                  id="nationalId"
                  name="nationalId"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nationalId}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
                />
                {formik.touched.nationalId && formik.errors.nationalId ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.nationalId}
                  </div>
                ) : null}
              </div>

              <button
                type="submit"
                className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                تایید
              </button>
            </div>
          )}
        </form>
      </div>
      <Footer />
    </>
  );
};
export default Signpage;
