import React, { useEffect, useState } from "react";
import {
  FaAngleDown,
  FaAngleLeft,
  FaCartShopping,
  FaPlus,
} from "react-icons/fa6";

import { BASE_URL, CDN_BASE_URL } from "../config";
import { Helmet } from "react-helmet";
import { priceComma } from "../utils/priceComma";
import { httpReauest } from "utils/httpRequest";
import Header from "components/header";
import Navbar from "components/dashboard/Navbar";
import Footer from "components/Footer";
import Loading from "components/common/Loading";
import ImageUpload from "components/dashboard/ImageUpload";
import { toast } from "react-toastify";
import { Add, Copy, Wallet } from "iconsax-react";
import { sendTransaction } from "utils/sendTransaction";

const WalletPage = () => {
  const [data, setData] = useState();
  const [orders, setorders] = useState([]);
  const [compeleteForm, setcompeleteForm] = useState(false);
  const [loading, setloading] = useState(false);
  const [selected, setselcted] = useState();
  const [img, setImg] = useState();
  const [amount, setamount] = useState();
  const [previewImg, setPreviewImg] = useState();

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user?.token) {
      window.location.href = window.origin + "/signup";
    }
    getData();
  }, [compeleteForm]);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newData = await httpReauest(
      "GET",
      "/user/transaction/" + user?._id,
      {},
      { "x-access-token": user?.token }
    ).catch(() => {
      localStorage.setItem("user", null);
      window.location.href = window.origin + "/sign";
    });

    setData(newData.data.data.user);
    setorders(newData.data.data.transactions);
  }

  async function handlePay(params) {
    if (!amount) {
      return toast.error("مقدار خالی است");
    }
    await sendTransaction({ amount });
  }

  return (
    <>
      {loading && (
        <div className="fixed left-0 top-0 w-full h-full bg-black/40 flex justify-center items-center">
          <Loading />
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"پنل کاربری"}</title>
        <meta name="og:title" content={"آریانا پوشاک | پنل کاربری"} />
      </Helmet>
      <Header />
      <div
        dir="rtl"
        className="py-6 lg:py-24  flex justify-center bg-gray-100  mt-[108px] lg:mt-[83px] text-right"
      >
        <div className=" w-full container px-[8px] lg:px-10">
          <div className="lg:grid-cols-4 grid gap-3">
            <Navbar user={data} />

            <span className="   lg:col-span-3 ">
              <>
                <div className="rounded-[12px] p-3 bg-white ">
                  <h2 className="font-bold text-lg mb-3">کیف پول </h2>
                  <div className="grid lg:grid-cols-2 gap-3">
                    <span className="lg:p-4 p-3 rounded-[8px] bg-blue-400 text-white">
                      <div className="flex gap-2 items-center text-[16px] lg:text-[18px]">
                        <span className="rounded-full bg-blue-300 p-2">
                          <Wallet size={24} />
                        </span>
                        موجودی کیف پول
                      </div>
                      <div
                        dir="ltr"
                        className="text-center text-[32px] font-bold mt-3"
                      >
                        {priceComma(data?.balance, 2)}{" "}
                        <span className="font-medium text-[18px]">AED</span>
                      </div>
                    </span>
                    <span className="lg:p-4 p-3 rounded-[8px] border">
                      <div className="flex gap-2 items-center text-[16px] lg:text-[18px]">
                        <span className="rounded-full border p-2">
                          <Add size={24} />
                        </span>
                        افزایش موجودی
                      </div>
                      <div className="w-full flex gap-2 items-center mt-4 text-[18px]">
                        <span className="relative w-full">
                          <input
                            placeholder="مبلغ را وارد کنید..."
                            className="w-full rounded-[8px] border outline-none py-2.5 px-3 "
                            onChange={(e) => setamount(e.target.value)}
                            type="number"
                          />
                          <span className="absolute left-2 top-1/2 -translate-y-1/2 font-bold">
                            AED
                          </span>
                        </span>
                        <button
                          onClick={handlePay}
                          className="px-4 py-2.5 rounded-[8px] h-full text-white font-bold bg-green-600"
                        >
                          پرداخت
                        </button>
                      </div>
                    </span>
                  </div>
                  <h2 className="font-bold text-lg my-3">تراکنش ها</h2>
                  <div className="border p-[12px]  bg-gray-200 duration-200 cursor-pointer">
                    <div className="flex justify-between items-center text-[14px] lg:text-[16px]">
                      <span>شماره تراکنش</span>
                      <span>تاریخ</span>
                      <span>مقدار</span>
                      <span>ارز_شبکه</span>
                    </div>
                  </div>
                  {orders?.map((item) => (
                    <div className="border">
                      <div className="flex justify-between items-center cursor-pointer duration-200 p-[12px]  ">
                        <span className=" font-bold text-[14px] lg:text-[18px] flex gap-1 items-center">
                          {item?.uuid}#
                        </span>

                        <span dir="ltr" className="lg:text-[16px] text-[12px] ">
                          {new Date(item.createdAt).toLocaleDateString()}
                        </span>
                        <span dir="ltr" className="lg:text-[16px] text-[12px] ">
                          {priceComma(item?.amount, 2)}
                        </span>
                        <span dir="ltr" className="lg:text-[16px] text-[12px] ">
                          {item?.currency}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WalletPage;
