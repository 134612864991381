import React, { useEffect, useState } from "react";
import {
  FaAngleDown,
  FaAngleLeft,
  FaCartShopping,
  FaPlus,
} from "react-icons/fa6";

import { BASE_URL, CDN_BASE_URL } from "../config";
import { Helmet } from "react-helmet";
import { priceComma } from "../utils/priceComma";
import { httpReauest } from "utils/httpRequest";
import Header from "components/header";
import Navbar from "components/dashboard/Navbar";
import Footer from "components/Footer";
import Loading from "components/common/Loading";
import ImageUpload from "components/dashboard/ImageUpload";
import { toast } from "react-toastify";
import { Copy } from "iconsax-react";

const BillsPage = () => {
  const [data, setData] = useState();
  const [orders, setorders] = useState([]);
  const [compeleteForm, setcompeleteForm] = useState(false);
  const [loading, setloading] = useState(false);
  const [selected, setselcted] = useState();
  const [img, setImg] = useState();
  const [previewImg, setPreviewImg] = useState();

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user?.token) {
      window.location.href = window.origin + "/signup";
    }
    getData();
  }, [compeleteForm]);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newData = await httpReauest(
      "GET",
      "/user/bills/" + user?._id,
      {},
      { "x-access-token": user?.token }
    ).catch(() => {
      localStorage.setItem("user", null);
      window.location.href = window.origin + "/sign";
    });

    setData(newData.data.data.user);
    setorders(newData.data.data.orders);
  }

  async function handleUpload(id) {
    if (!img) {
      return toast.error("تصویری بارگزاری نشده");
    }
    const user = JSON.parse(localStorage.getItem("user"));

    const formdata = new FormData();
    formdata.append("img", img, img.name);
    await fetch(BASE_URL + "/order/pay/" + id, {
      method: "POST",
      body: formdata,
      headers: { "x-access-token": user?.token },
    }).then((res) => {
      if (res.status === 201) {
        setselcted(null);
        setImg(null);
        setPreviewImg(null);
        toast.success("موفقیت آمیز");
        getData();
      }
    });
  }

  async function handlePayWithBalance(id) {
    const user = JSON.parse(localStorage.getItem("user"));

    await fetch(BASE_URL + "/order/pay/balance/" + id, {
      method: "POST",
      body: {},
      headers: { "x-access-token": user?.token },
    }).then((res) => {
      if (res.status === 201) {
        setselcted(null);
        setImg(null);
        setPreviewImg(null);
        toast.success("موفقیت آمیز");
        getData();
      }
    });
  }

  return (
    <>
      {loading && (
        <div className="fixed left-0 top-0 w-full h-full bg-black/40 flex justify-center items-center">
          <Loading />
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"پنل کاربری"}</title>
        <meta name="og:title" content={"آریانا پوشاک | پنل کاربری"} />
      </Helmet>
      <Header />
      <div
        dir="rtl"
        className="py-6 lg:py-24  flex justify-center bg-gray-100  mt-[108px] lg:mt-[83px] text-right"
      >
        <div className=" w-full container px-[8px] lg:px-10">
          <div className="lg:grid-cols-4 grid gap-3">
            <Navbar user={data} />

            <span className="   lg:col-span-3 ">
              <>
                <div className="rounded-[12px] p-3 bg-white ">
                  <h2 className="font-bold text-lg mb-3">فاکتور ها </h2>
                  <div className="border p-[12px]  bg-gray-200 duration-200 cursor-pointer">
                    <div className="flex justify-between items-center text-[14px] lg:text-[16px]">
                      <span>شماره فاکتور</span>
                      <span>تاریخ</span>
                      <span>وضعیت</span>
                    </div>
                  </div>
                  {orders?.map((item) => (
                    <div className="border  ">
                      <div
                        onClick={() =>
                          setselcted(selected === item?._id ? null : item?._id)
                        }
                        className="flex justify-between items-center cursor-pointer hover:bg-slate-100 duration-200 p-[12px]  "
                      >
                        <span className=" font-bold text-[14px] lg:text-[18px] flex gap-1 items-center">
                          <FaAngleDown />
                          فاکتور {item?.id}#
                        </span>
                        <span dir="ltr" className="lg:text-[16px] text-[12px] ">
                          {new Date(item.createdAt).toLocaleDateString()}
                        </span>
                        {item?.pay_img ? (
                          <span className="font-bold bg-yellow-200 px-3 rounded text-yellow-600 pt-1 lg:text-[16px] text-[12px]">
                            در انتظار تایید
                          </span>
                        ) : item?.status !== "تایید نشده" ? (
                          <span className="font-bold bg-green-200 px-3 rounded text-green-600 pt-1 lg:text-[16px] text-[12px]">
                            پرداخت شده
                          </span>
                        ) : (
                          <span className="font-bold bg-red-200 px-3 rounded text-red-600 pt-1 lg:text-[16px] text-[12px]">
                            پرداخت نشده
                          </span>
                        )}
                      </div>
                      <div
                        className={`${
                          selected === item?._id ? "max-h-[999px]" : "max-h-0"
                        } duration-200 overflow-hidden `}
                      >
                        <div className="grid grid-cols-2  p-[12px] mt-2 lg:text-[16px] text-[14px]">
                          <span className="border-r-2 border-red-500 ps-2 ">
                            تاریخ صورتحساب: <br />{" "}
                            <strong>
                              {new Date(item.createdAt).toLocaleDateString()}
                            </strong>
                          </span>
                          <span className="border-r-2 border-blue-500 ps-2 ">
                            تاریخ سررسید:
                            <br />{" "}
                            <strong>
                              {new Date(item.createdAt).toLocaleDateString()}
                            </strong>
                          </span>
                        </div>
                        <div className="mt-3 flex justify-center p-[12px] flex-col items-center">
                          <h5 className="mb-2">مشخصات سفارش</h5>
                          {item?.products?.map((pro) => (
                            <div class="border-b bg-neutral-100 flex justify-between  items-center lg:text-[16px] text-[12px]">
                              <td class="whitespace-nowrap px-3 lg:px-4 py-2">
                                {pro?.product?.name} {pro?.color} {pro?.storage}{" "}
                                {pro?.count}x
                              </td>
                              <td class="whitespace-nowrap px-3 lg:px-4 py-2">
                                {pro?.price}AED
                              </td>
                            </div>
                          ))}
                          {!item?.pay_img && (
                            <div className="mt-2">
                              <p className=" lg:text-[16px] text-[14px]">
                                آپلود فیش پرداختی
                              </p>
                              <label
                                htmlFor={"payment"}
                                className="border-[2px] bg-white border-dashed min-w-[320px] rounded-[12px] h-[170px] w-full flex justify-center items-center cursor-pointer p-2"
                              >
                                {previewImg ? (
                                  <img
                                    src={previewImg}
                                    className="w-full h-full object-contain"
                                    alt=""
                                  />
                                ) : (
                                  <FaPlus size={44} />
                                )}
                              </label>

                              <input
                                onChange={(e) => {
                                  handleImg(e);
                                }}
                                type="file"
                                className="hidden"
                                id={"payment"}
                                accept="image/png, image/jpg, image/jpeg"
                              />
                              <span className="relative">
                                <input
                                  dir="ltr"
                                  disabled
                                  class="shadow-sm bg-gray-100 border rounded p-2 mt-2 mb-1 h-[42px] w-full"
                                  value="5022-2910-1917-7474"
                                  data-listener-added_1639567b="true"
                                />
                                <Copy
                                  className="absolute right-2 top-[13px] -translate-y-1/2 cursor-pointer"
                                  size={22}
                                  color="blue"
                                />
                                <input
                                  dir="ltr"
                                  disabled
                                  class="shadow-sm bg-gray-100 border rounded p-2 mt-2 mb-1 h-[42px] w-full"
                                  value="184567891239875022291019177474"
                                  data-listener-added_1639567b="true"
                                />
                                <Copy
                                  className="absolute right-2 top-[66px] -translate-y-1/2 cursor-pointer"
                                  size={22}
                                  color="blue"
                                />
                                <span className="text-right w-full">
                                  شرکت امپریوم
                                </span>
                              </span>

                              <h5 className="mt-2 text-center text-orange-500 font-bold text-[18px]">
                                قابل پرداخت: {priceComma(item?.allPrice, 2)}AED
                              </h5>
                              <h5 className="mt-2 text-center text-blue-500 font-bold text-[18px]">
                                موجودی: {priceComma(data?.balance, 2)}AED
                              </h5>
                              <div className="flex justify-center gap-1">
                                <button
                                  onClick={() => handleUpload(item?._id)}
                                  className="bg-red-500 text-white px-3 py-1 rounded-[8px] mt-2 hover:bg-red-700 "
                                >
                                  ارسال فیش
                                </button>
                                <button
                                  onClick={() => handlePayWithBalance(item?._id)}
                                  disabled={data?.balance < item?.allPrice}
                                  className="bg-blue-500 text-white px-3 py-1 rounded-[8px] mt-2 hover:bg-blue-700 disabled:bg-gray-500 disabled:opacity-75"
                                >
                                  پرداخت با موجودی
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BillsPage;
