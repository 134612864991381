import {
  DocumentText,
  Headphone,
  Layer,
  Logout,
  LogoutCurve,
  Wallet,
} from "iconsax-react";
import React from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ user }) => {
  const navigate = useNavigate();
  return (
    <span className="p-3 rounded-[12px] flex flex-col items-center  justify-center gap-1 h-fit bg-white">
      <Link
        to={"/dashboard"}
        className="flex justify-between gap-2 items-center w-full border-b pb-3"
      >
        <span>
          <h5 className="text-[22px] mb-2 text-black">{user?.name}</h5>
          <h6 className="font-bold text-[16px] text-slate-800">
            {user?.phone}
          </h6>
        </span>
        <span>
          <FaAngleLeft />
        </span>
      </Link>
      <div className="w-full flex flex-col gap-2 pt-2">
        <Link
          to={"/dashboard/wallet"}
          className="flex justify-between items-center w-full hover:bg-slate-200 duration-200 p-2 rounded-md"
        >
          <span className="flex items-center gap-2 text-[#111] text-[16px]">
            <Wallet size={26} color="#111" />
            کیف پول
          </span>
          <span>
            <FaAngleLeft />
          </span>
        </Link>
        <Link
          to={"/dashboard/orders"}
          className="flex justify-between items-center w-full hover:bg-slate-200 duration-200 p-2 rounded-md"
        >
          <span className="flex items-center gap-2 text-[#111] text-[16px]">
            <Layer size={26} color="#111" />
            سفارش ها
          </span>
          <span>
            <FaAngleLeft />
          </span>
        </Link>
        <Link
          to={"/dashboard/bills"}
          className="flex justify-between items-center w-full hover:bg-slate-200 duration-200 p-2 rounded-md"
        >
          <span className="flex items-center gap-2 text-[#111] text-[16px]">
            <DocumentText size={26} color="#111" />
            فاکتور ها
          </span>
          <span>
            <FaAngleLeft />
          </span>
        </Link>
        <Link className="flex justify-between items-center w-full hover:bg-slate-200 duration-200 p-2 rounded-md">
          <span className="flex items-center gap-2 text-[#111] text-[16px]">
            <Headphone size={26} color="#111" />
            پشتیبانی
          </span>
          <span>
            <FaAngleLeft />
          </span>
        </Link>
        <Link
          onClick={() => {
            localStorage.setItem("user", null);
            navigate("/sign");
          }}
          className="flex justify-between items-center w-full hover:bg-slate-200 duration-200 p-2 rounded-md"
        >
          <span className="flex items-center gap-2 text-[#111] text-[16px]">
            <LogoutCurve className="rotate-180" size={26} color="#111" />
            خروج
          </span>
          <span>
            <FaAngleLeft />
          </span>
        </Link>
      </div>
    </span>
  );
};

export default Navbar;
