import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductGallery from "../components/ProductGallery";
import SliderSimilier from "../components/SliderSimilier";
import Footer from "../components/Footer";
import { httpReauest } from "../utils/httpRequest";
import { CDN_BASE_URL } from "../config";
import { CartContext } from "../context/CartContext";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import { calculateGoldPrice } from "../utils/getPrice";
import { priceComma } from "../utils/priceComma";
import { Helmet } from "react-helmet";
import { BsShare, BsTelegram } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import Header from "components/header";
import { useTranslation } from "react-i18next";
import Reveal from "motion/Reveal";

const ProductDetailsPage = () => {
  const [productId, setProductId] = useState([]);
  const [subProducts, setsubProducts] = useState();
  const [count, setCount] = useState(1);
  const [loading, setloading] = useState(true);
  const [selected, setselected] = useState();
  const [selectedcolor, setselectedcolor] = useState();
  const [selctedsize, setselctedsize] = useState();
  const [balance, setBalance] = useState();
  const [onCart, setonCart] = useState();
  const [countProduct, setcountProduct] = useState(1);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ImageGallery, setImageGallery] = useState([]);
  const { name } = useParams();

  const { cart, setCart } = useContext(CartContext);

  const [t, i18] = useTranslation("global");

  async function getId() {
    setloading(true);
    const response = await httpReauest("GET", `/product/${name}`, {}, {});
    setProductId(response.data.data.product);
    if (response.data.data.subproducts.length) {
      handlecolor(response.data.data.subproducts);
      setsubProducts(response.data.data.subproducts);
    }
    const isoncart = cart?.find((item) => item?._id == response.data.data._id);
    if (isoncart) {
      setonCart(isoncart);
    }
    setloading(false);
  }

  function handlecolor(e) {
    const groupedByColor = e?.reduce((acc, item) => {
      if (!acc[item.color]) {
        acc[item.color] = [];
      }
      acc[item.color].push(item);
      return acc;
    }, {});

    // تبدیل به آرایه از اشیا گروه‌بندی شده
    if (groupedByColor && Object.keys(groupedByColor).length > 0) {
      const result = Object.entries(groupedByColor).map(
        ([color, subproducts]) => ({ color, image: subproducts[0]?.image })
      );

      setColors(result);
    } else {
      console.error("No items grouped by color or groupedByColor is undefined");
    }
  }

  function handleChose(e) {
    const sizes = subProducts.filter((c) => c.color === e);
    setselectedcolor(e);
    setSizes(sizes);
  }

  function handleBalance(e) {
    const foundItem = subProducts.find(
      (item) =>
        item.color === selectedcolor &&
        (item.storage ? item.storage : item?.size) == e
    );
    setBalance(foundItem);
  }

  useEffect(() => {
    getId();
    window.scrollTo({ top: 0 });
  }, [name]);

  function handleAdd() {
    if (balance?.balance === 0) {
      return toast.error("محصول موجود نمیباشد");
    }
    if (subProducts?.length) {
      if (!selectedcolor || !balance) {
        return toast.warn("لطفا قبل سفارش مشخصات را تایین کنید");
      }
      const newCart = {
        name: productId?.name,
        color: selectedcolor,
        size: balance?.storage ? balance?.storage : balance?.size,
        img: productId?.images[0],
        price: balance?.special_price ? balance?.special_price : balance?.price,
        count: count,
        id: balance?._id,
      };

      const cartExited = cart.find((e) => e.id === newCart.id);
      if (cartExited) {
        if (cartExited.count + newCart.count > balance.balance) {
          return toast.error("محصول به این تعداد نمیباشد");
        }
      }

      setCart((prevCart) => {
        const cartExited2 = prevCart.find((e) => e.id === newCart.id);
        if (cartExited2) {
          return prevCart.map((item) =>
            item.id === cartExited2.id
              ? { ...item, count: item.count + newCart.count }
              : item
          );
        } else {
          return [...prevCart, newCart];
        }
      });
    }
  }

  function handleDelete() {
    const newCart = cart.filter((e) =>
      e._id != selected ? selected?._id : productId?._id
    );
    setCart(newCart);
    setonCart(false);
  }

  function handleAddCount() {
    if (selected) {
      if (selected.balance < countProduct + 1) {
        return toast.error("محصول به این تعداد موجود نیست");
      }
    } else {
      if (productId.balance < countProduct + 1) {
        return toast.error("محصول به این تعداد موجود نیست");
      }
    }

    setcountProduct(countProduct + 1);

    const newCart = cart.filter((e) =>
      e._id != selected ? selected?._id : productId?._id
    );
    const productCard = {
      name: productId.name,
      weight: selected ? selected.weight : productId.weight,
      makingFee: selected ? selected.makingFee : productId.makingFee,
      balance: selected ? selected.balance : productId.balance,
      _id: selected ? selected._id : productId._id,
      img: productId.img,
      isSub: selected?.product ? selected?.product : false,
      count: countProduct + 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  function handleDecreseCount() {
    setcountProduct(countProduct - 1);
    const newCart = cart.filter((e) =>
      e._id != selected ? selected?._id : productId?._id
    );
    const productCard = {
      name: productId.name,
      weight: selected ? selected.weight : productId.weight,
      makingFee: selected ? selected.makingFee : productId.makingFee,
      balance: selected ? selected.balance : productId.balance,
      _id: selected ? selected._id : productId._id,
      img: productId.img,
      isSub: selected?.product ? selected?.product : false,
      count: countProduct - 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  const handleChangeImage = (index) => {
    setCurrentIndex(index);
  };

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <div className="mt-[108px] lg:mt-[83px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{productId?.name}</title>
        <meta name="description" content={productId?.desc} />
        <meta name="og:description" content={productId?.desc} />
        <meta name="og:title" content={productId?.name} />
      </Helmet>{" "}
      <Header />
      {/* {share && (
        <Modal
          open={share}
          onClose={() => setshare(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div dir="rtl" style={style}>
            <ShareModal desktop url={url} setShare={setshare} />
          </div>
        </Modal>
      )}
      {share2 && <ShareModal desktop={false} url={url} setShare={setshare2} />} */}
      <div className=" overflow-hidden  pb-10  bg-white ">
        <div className="lg:grid grid-cols-2  flex flex-col justify-center items-center container">
          <h1 className="font-bold text-[18px] w-full lg:text-[27px] py-2 border-b lg:hidden">
            {i18.language == "fa" ? productId?.name : productId?.name_en}
          </h1>
          <div className=" w-full z-10 ">
            <Reveal>
              <ProductGallery
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                ImageGallery={ImageGallery}
                setImageGallery={setImageGallery}
                images={productId?.images}
              />
            </Reveal>
          </div>
          <div className="col-span-1 w-full lg:p-5">
            <Reveal head>
              <div className="">
                <h1 className="font-bold text-[18px] w-full lg:text-[27px] py-3 border-b hidden lg:block">
                  {i18.language == "fa" ? productId?.name : productId?.name_en}
                </h1>
                <p className="py-3">
                  {" "}
                  {i18.language == "fa" ? productId?.desc : productId?.desc_en}
                </p>
              </div>
            </Reveal>

            <div className="flex justify-between py-3 items-center border-t">
              <h5
                dir="rtl"
                className=" font-bold text-left text-[20px] lg:text-[25px]"
              >
                {balance ? (
                  <div className="flex gap-2 flex-wrap">
                    {balance?.price ? (
                      <span>
                        {t("price")} : {priceComma(balance?.price, 2)}{" "}
                        {t("toman")}
                      </span>
                    ) : null}
                  </div>
                ) : (
                  <></>
                )}
              </h5>
            </div>
            <Reveal head>
              <div className="grid gap-[12px]  mt-4">
                <span className="flex gap-3 items-center text-black">
                  <span className="font-[500] text-[16px]">{t("color")}</span>
                  <select
                    onChange={(e) => {
                      const newValue = JSON.parse(e.target.value);
                      if (newValue?.image) {
                        setImageGallery((c) => [
                          ...c,
                          {
                            original: newValue.image,
                            thumbnail: "",
                          },
                        ]);
                        handleChangeImage(ImageGallery.length);
                      }

                      handleChose(newValue.color);
                    }}
                    className="form-select w-full"
                  >
                    <option>{t("choose-option")}</option>
                    {colors.map((item) => (
                      <option value={JSON.stringify(item)}>{item.color}</option>
                    ))}
                  </select>
                </span>
                {sizes.length ? (
                  <span className="flex gap-3 items-center text-black">
                    <span className="font-[500] text-[16px]">
                      {sizes[0]?.storage ? t("storage") : t("storage")}
                    </span>
                    <select
                      onChange={(e) => handleBalance(e.target.value)}
                      className="form-select w-full"
                    >
                      <option>{t("choose-option")}</option>
                      {sizes?.map((item) => {
                        if (item.storage) {
                          return <option>{item.storage}</option>;
                        } else {
                          return <option>{item.size}</option>;
                        }
                      })}
                    </select>
                  </span>
                ) : null}
                {balance && (
                  <span className="bg-gray-200 px-3 py-2 rounded-lg w-fit">
                    {t("balance")} : {balance?.balance}
                  </span>
                )}
              </div>
            </Reveal>
            {/* <Reveal head>
              <div className="my-8 flex gap-2 items-center">
                <Link
                  target="_blank"
                  to="https://wa.me/971501926568"
                  className="btn bg-red-500 text-white"
                >
                  {t("get-price")}
                </Link>
              </div>
            </Reveal> */}

            <div className="my-8 flex gap-2 items-center">
              <span className="py-1 w-fit flex items-center border rounded-full mx-2">
                <button
                  onClick={() => {
                    if (count === balance?.balance || !balance?.balance) {
                      return;
                    } else setCount(count + 1);
                  }}
                  className=" px-2 py-1 "
                >
                  <FaPlus />
                </button>
                <span className="px-2 py-1">{count}</span>
                <button
                  onClick={() => {
                    if (count === 1) {
                      return;
                    } else setCount(count - 1);
                  }}
                  className="px-2 py-1 "
                >
                  <FaMinus />
                </button>
              </span>

              <button onClick={handleAdd} className=" btn btn-primary">
                {t("add-to-cart")}
              </button>
            </div>
          </div>
          <div className="col-span-full mt-6 lg:mt-16 w-full">
            <h3 className="font-bold text-[18px] lg:text-[27px]  border-b pb-3">
              {t("more-desc")}
            </h3>
            <div
              dir="rtl"
              className="lg:text-[16px] text-[14px] pt-3"
              dangerouslySetInnerHTML={{ __html: productId?.more_desc }}
            />
          </div>
        </div>

        <div className="w-full   mt-10">
          <span className="font-bold text-black text-[30px] lg:text-[40px] pt-8 text-center mb-6 block ">
            {t("related-products")}
          </span>
          {productId?.category && (
            <SliderSimilier category={productId?.category?.url} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
