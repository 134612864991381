import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "components/header";
import Footer from "components/Footer";
import { httpReauest } from "utils/httpRequest";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const PartnerSignpage = () => {
  const [step, setStep] = useState(1);

  const naviagte = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      naviagte("/dashboard");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("الزامی است"),
      password: Yup.string().required("الزامی است"),
    }),
    onSubmit: async (values) => {
      await httpReauest(
        "POST",
        "/partner/login/",
        {
          username: values.username,
          password: values.password,
        },
        {}
      )
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res?.data?.data));
          naviagte("/dashboard");
        })
        .catch(() => toast.error("نام کاربری یا رمز عبور اشتباه است"));
    },
  });

  return (
    <>
      <div className="min-h-[80vh] flex items-center justify-center bg-gray-100   mt-[108px] lg:mt-[83px]">
        <Header />
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white px-8 pb-3 rounded-lg shadow-md w-96"
          dir="rtl"
        >
          <img
            src="/logo.png"
            className="w-full max-w-[270px] mx-auto"
            alt="Emporium point logo"
          />
          <h2 className="text-2xl font-bold mb-6 ">ورود به عنوان همکار</h2>
          {step === 1 && (
            <div>
              <div className="mb-2">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  نام کاربری
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  رمز عبور
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>

              <button
                type="submit"
                className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                ورود
              </button>
            </div>
          )}
        </form>
      </div>
      <Footer />
    </>
  );
};
export default PartnerSignpage;
