import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, CDN_BASE_URL } from "../config";

const FileUploadComponent = ({ orderId }) => {
  const [files, setFiles] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Fetch previous files when component mounts
  useEffect(() => {
    const fetchPreviousFiles = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await axios.get(`${BASE_URL}/order/${orderId}`, {
          headers: { "x-access-token": user.token },
        });

        if (response.data?.data.files && response.data?.data.files.length > 0) {
          setPreviousFiles(response.data?.data.files);
        }
      } catch (err) {
        console.error("Error fetching previous files:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPreviousFiles();
  }, [orderId, success]); // Refetch when upload is successful

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).map((file) => ({
      file,
      name: "",
      isNew: true, // Mark as new file
    }));
    setFiles([...files, ...newFiles]);
  };

  const handleNameChange = (index, value) => {
    const updatedFiles = [...files];
    updatedFiles[index].name = value;
    setFiles(updatedFiles);
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

    try {
      const formData = new FormData();
      const names = [];

      // Add only new files to FormData
      files
        .filter((file) => file.isNew)
        .forEach((item) => {
          formData.append("files", item.file);
          names.push(item.name);
        });

      const user = JSON.parse(localStorage.getItem("user"));

      // Add names array to FormData
      formData.append("names", JSON.stringify(names));

      await fetch(`${BASE_URL}/order/files/${orderId}`, {
        method: "PUT",
        body: formData,
        headers: { "x-access-token": user.token },
      });

      setSuccess(true);
      setFiles(files.filter((file) => !file.isNew)); // Keep only non-new files
    } catch (err) {
      setError(err.response?.data?.message || "خطایی رخ داده است");
    } finally {
      setIsSubmitting(false);
    }
  };

  const openFileInNewTab = (fileUrl) => {
    window.open(`${CDN_BASE_URL}${fileUrl}`, "_blank");
  };

  if (isLoading) {
    return <div>در حال بارگذاری...</div>;
  }

  return (
    <div className="file-upload-container min-w-[340px] lg:min-w-[400px]" dir="rtl">
      <h2 className="text-lg font-bold mb-4">مدیریت فایل ها</h2>

      {/* Previous files section */}
      {previousFiles.length > 0 && (
        <div className="mb-6">
          <h3 className="text-md font-semibold mb-2">فایل ها:</h3>
          <div className="space-y-3">
            {previousFiles.map((file, index) => (
              <div
                key={`prev-${index}`}
                className="flex justify-between items-center p-3 border rounded"
              >
                <div>
                  <span className="text-sm">
                    {file.title || `فایل ${index + 1}`}
                  </span>
                </div>
                <button
                  onClick={() => openFileInNewTab(file.file)}
                  className="bg-blue-500 text-white text-sm rounded px-3 py-1"
                >
                  مشاهده فایل
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
