import React, { useEffect, useState } from "react";
import { FaAngleLeft, FaCartShopping, FaFile, FaTable } from "react-icons/fa6";

import { BASE_URL, CDN_BASE_URL } from "../config";
import { Helmet } from "react-helmet";
import { priceComma } from "../utils/priceComma";
import { httpReauest } from "utils/httpRequest";
import Header from "components/header";
import Navbar from "components/dashboard/Navbar";
import Footer from "components/Footer";
import Loading from "components/common/Loading";
import { Link } from "react-router-dom";
import FileUploadComponent from "components/OrderFiles";

const OrdersPage = () => {
  const [data, setData] = useState();
  const [orders, setorders] = useState([]);
  const [compeleteForm, setcompeleteForm] = useState(false);
  const [loading, setloading] = useState(false);
  const [selected, setselcted] = useState();
  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user?.token) {
      window.location.href = window.origin + "/signup";
    }
    getData();
  }, [compeleteForm]);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newData = await httpReauest(
      "GET",
      "/user/info/" + user?._id,
      {},
      { "x-access-token": user?.token }
    ).catch(() => {
      localStorage.setItem("user", null);
      window.location.href = window.origin + "/signup";
    });

    setData(newData.data.data.user);
    setorders(newData.data.data.orders);
  }

  return (
    <>
      {loading && (
        <div className="fixed left-0 top-0 w-full h-full bg-black/40 flex justify-center items-center">
          <Loading />
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"پنل کاربری"}</title>
        <meta name="og:title" content={"پنل کاربری"} />
      </Helmet>
      <Header />
      {showUpload && (
        <>
          <div
            className="w-full h-full fixed top-0 left-0 bg-black/50 z-30"
            onClick={() => setShowUpload(false)}
          ></div>
          <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-[16px] p-4 z-40 ">
            <FileUploadComponent orderId={selected?._id} />
          </div>
        </>
      )}
      <div
        dir="rtl"
        className="py-6 lg:py-24  flex justify-center bg-gray-100  mt-[108px] lg:mt-[83px] text-right"
      >
        <div className=" w-full container px-[8px] lg:px-10">
          <div className="lg:grid-cols-4 grid gap-3">
            <Navbar user={data} />

            <span className="   lg:col-span-3 ">
              <>
                {selected ? (
                  <div className="rounded-[12px] p-3 bg-white ">
                    <h2 className="font-bold text-lg justify-between items-center flex">
                      جزئیات سفارش {selected?.id}#{" "}
                      <div className="flex flex-wrap items-center gap-2">
                        <button
                          onClick={() => setShowUpload(true)}
                          className="bg-emerald-600 text-white flex gap-1 items-center px-3 text-[14px] font-[400] rounded-[8px] py-1 cursor-pointer "
                        >
                          فایل ها
                          <FaFile />
                        </button>
                        <Link
                          to={BASE_URL + "/order/csv/" + selected?._id}
                          download
                          className="bg-blue-600 text-white flex gap-1 items-center px-3 text-[14px] font-[400] rounded-[8px] py-1 cursor-pointer "
                        >
                          خروجی اکسل
                          <FaTable />
                        </Link>
                        <button
                          onClick={() => setselcted(null)}
                          className="bg-red-500 text-white flex gap-1 items-center px-3 text-[14px] font-[400] rounded-[8px] py-1 cursor-pointer "
                        >
                          بازگشت
                          <FaAngleLeft />
                        </button>
                      </div>
                    </h2>
                    <div className="mt-3">
                      <ol class="flex  items-center w-full  text-center text-gray-500 dark:text-gray-400 text-[14px]">
                        <li class="flex md:w-full items-center text-green-600 dark:text-green-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                          <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500 whitespace-nowrap">
                            <svg
                              class="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            منتظر تایید
                          </span>
                        </li>
                        <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                          <span
                            class={`${
                              selected.status == "تایید شده منتظر ارسال" ||
                              selected.status == "ارسال شده"
                                ? "dark:after:text-green-500 text-green-600 dark:text-green-500"
                                : "dark:after:text-gray-500 text-gray-600 dark:text-gray-500"
                            } flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200  whitespace-nowrap`}
                          >
                            {selected.status == "تایید شده منتظر ارسال" ||
                            selected.status == "ارسال شده" ? (
                              <svg
                                class="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                            ) : (
                              <span class="me-2">2</span>
                            )}
                            در انتظار ارسال
                          </span>
                        </li>
                        <li
                          class={`${
                            selected.status == "ارسال شده"
                              ? "dark:after:text-green-500 text-green-600 dark:text-green-500"
                              : "dark:after:text-gray-500 text-gray-600 dark:text-gray-500"
                          } flex items-center whitespace-nowrap `}
                        >
                          {selected.status == "ارسال شده" ? (
                            <svg
                              class="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                          ) : (
                            <span class="me-2">3</span>
                          )}
                          ارسال شده
                        </li>
                      </ol>
                      <div className="flex justify-between mt-3 items-end flex-wrap gap-3 w-full overflow-x-auto">
                        <div class="overflow-x-auto w-full max-w-[94vw]">
                          <div class="inline-block  py-2 w-full">
                            <div class="">
                              <table class=" text-left text-sm font-light w-full">
                                <thead class="border-b  font-medium border-neutral-500 text-white bg-red-600">
                                  <tr>
                                    <th scope="col" class="px-6 py-3">
                                      نام محصول
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                      رنگ
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                      تعداد
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                      حافظه
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                      سایز
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                      قیمت
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                      کد imei
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selected?.products?.map((item) => (
                                    <tr class="border-b bg-neutral-100 ">
                                      <td class="whitespace-nowrap px-6 py-3">
                                        {item?.product?.name}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-3">
                                        {item?.color}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-3">
                                        {item?.count}x
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-3">
                                        {item?.storage ? item?.storage : "___"}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-3">
                                        {item?.size ? item?.size : "___"}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-3">
                                        {item?.price}
                                      </td>
                                      <td class="whitespace-nowrap px-6 py-3">
                                        {item?.codes?.length
                                          ? item?.codes?.map((code) => (
                                              <>
                                                {code}
                                                <br />
                                              </>
                                            ))
                                          : "___"}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <span className="font-bold text-[22px]">
                        AED {priceComma(selected?.allPrice, 2)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="rounded-[12px] p-3 bg-white ">
                    <h2 className="font-bold text-lg">سفارشات </h2>
                    {orders?.map((item) => (
                      <div
                        onClick={() => setselcted(item)}
                        className="border p-[12px] rounded-md mt-3 hover:bg-slate-100 duration-200 cursor-pointer"
                      >
                        <div className="mb-2 flex justify-between items-center">
                          <span className=" font-bold text-[14px] lg:text-[18px]">
                            سفارش {item?.id}#
                          </span>
                          <span
                            dir="ltr"
                            className="font-bold bg-green-200 lg:text-[16px] text-[12px] px-3 rounded text-green-600 pt-1"
                          >
                            {new Date(item.createdAt).toLocaleString()}
                          </span>
                        </div>
                        <ol class="flex  items-center w-full  text-center text-gray-500 dark:text-gray-400 text-[14px]">
                          <li class="flex md:w-full items-center text-green-600 dark:text-green-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                            <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500 whitespace-nowrap">
                              <svg
                                class="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              منتظر تایید
                            </span>
                          </li>
                          <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                            <span
                              class={`${
                                item.status == "تایید شده منتظر ارسال" ||
                                item.status == "ارسال شده"
                                  ? "dark:after:text-green-500 text-green-600 dark:text-green-500"
                                  : "dark:after:text-gray-500 text-gray-600 dark:text-gray-500"
                              } flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200  whitespace-nowrap`}
                            >
                              {item.status == "تایید شده منتظر ارسال" ||
                              item.status == "ارسال شده" ? (
                                <svg
                                  class="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                              ) : (
                                <span class="me-2">2</span>
                              )}
                              در انتظار ارسال
                            </span>
                          </li>
                          <li
                            class={`${
                              item.status == "ارسال شده"
                                ? "dark:after:text-green-500 text-green-600 dark:text-green-500"
                                : "dark:after:text-gray-500 text-gray-600 dark:text-gray-500"
                            } flex items-center whitespace-nowrap `}
                          >
                            {item.status == "ارسال شده" ? (
                              <svg
                                class="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                            ) : (
                              <span class="me-2">3</span>
                            )}
                            ارسال شده
                          </li>
                        </ol>
                        <div className="flex justify-between mt-3 items-end flex-wrap gap-3">
                          <span className=" flex gap-2 flex-wrap">
                            {item?.products?.map((pro) => (
                              <span className="flex flex-col gap-1 items-center border p-1 text-[12px] lg:text-[16px]">
                                <span>{pro?.product?.name}</span>
                                <span>{pro?.storage}</span>
                                <span>
                                  {pro?.color} {pro?.count}x
                                </span>
                                <span>{priceComma(pro?.buy_price, 2)}AED</span>
                              </span>
                            ))}
                          </span>
                          <span className="font-bold text-[18px] lg:text-[22px]">
                            AED {priceComma(item?.allPrice, 2)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrdersPage;
