const url = "https://api.cryptocloud.plus/v2/invoice/create";
const headers = new Headers({
  Authorization:
    "Token eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiTWpNek9UTT0iLCJ0eXBlIjoicHJvamVjdCIsInYiOiJjMjk0OTYzZmIxNGJjOTIzMjEyZWViN2YxM2VlMDY2Y2IwNDZjMTVmYThhYzllNDc4ZTgyMzc1ZGI5ZjI3NGFmIiwiZXhwIjo4ODEyMDY4MzM5Mn0.oUrG2q4Ta7eIPaNCb-nCOVaXxE1BjAcsC9x-u-A7uP0",
  "Content-Type": "application/json",
});

export async function sendTransaction({ amount }) {
  const bodyfirst = {
    shop_id: "oxciEI2EiJGESFLb",
    amount,
    currency: "AED",
  };
  await fetch(url, { method: "POST", headers, body: JSON.stringify(bodyfirst) })
    .then(async (response) => {
      if (response.ok) {
        const res = await response.json();
        if (res.status == "success") {
          localStorage.setItem("uuid", res.result.uuid);
          window.location.href = res.result.link;
        }
      } else {
        return Promise.reject("Auth error");
      }
    })
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Fail:", error);
    });
}
